import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import Link from 'next/link';
import { routes } from '../../../constants/routes';
import CustomSpinner from '../../spinner';
import DashboardTaskList from '../dashboard-task-list';
import { ITasksListWrapperProps } from '../../../interfaces/Task';
import AddLink from '../../add-link';
import styles from './dashboard-task-list-wrapper.module.scss';

const DashboardTaskListWrapper = ({
  tasksData,
  isLoading,
  recordsFoundLabel,
}: ITasksListWrapperProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col>
          <Card className={styles['create-tasks-wrap']}>
            <Card.Header className={styles['create-tasks-header']}>
              <Row className="justify-content-between align-items-center">
                <h3 className={`${styles.title} col-6`}>
                  {t('dashboard.tasks.tasksToday')}
                </h3>
                <div className="d-flex justify-content-end col-6">
                  <AddLink
                    link={routes.createTask.path()}
                    title={routes.createTask.title}
                  />
                </div>
              </Row>
            </Card.Header>
            <Card.Body>
              {tasksData && !isLoading ? (
                <DashboardTaskList
                  tasksData={tasksData}
                  recordsFoundLabel={recordsFoundLabel}
                />
              ) : (
                <CustomSpinner />
              )}
              <Row>
                <Link href={routes.tasks.path()}>
                  <a className={styles['view-all-task-btn']}>
                    {t('dashboard.tasks.viewAllTasks')}
                  </a>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default DashboardTaskListWrapper;
DashboardTaskListWrapper.defaultProps = {
  recordsFoundLabel: '',
};
