import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { dateFormat } from '../../../constants/dateFormat';
import ServicesList from '../../services/services-list';
import DashboardTaskListWrapper from '../dashboard-task-list-wrapper';
import { IDashboardWrapperProps } from '../../../interfaces/Dashboard';
import styles from './dashboard-wrapper.module.scss';

const DashboardWrapper = ({
  taskData,
  serviceData,
  isTaskLoading,
  isServiceLoading,
}: IDashboardWrapperProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.title}>
        <span>{t('dashboard.title')}</span>
        <time>{moment().format(dateFormat)}</time>
      </div>
      <section>
        <ServicesList
          isCreateNewService
          title={t('dashboard.services.servicesToday')}
          isCount={false}
          isShowStatus={false}
          servicesList={serviceData}
          isShowAllTasks
          isLoading={isServiceLoading}
          recordsFoundLabel={t('dashboard.services.noServicesForToday')}
        />
      </section>
      <section>
        <DashboardTaskListWrapper
          tasksData={taskData}
          isLoading={isTaskLoading}
          recordsFoundLabel={t('dashboard.tasks.noTaskForToday')}
        />
      </section>
    </>
  );
};
export default DashboardWrapper;
