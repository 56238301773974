import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import {
  ITaskProps,
  ServicesListProps,
} from '@digital.jit/services/customer/ui-models';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import { EventDropArg } from '@fullcalendar/common';
import Container from '../../shared/components/container';
import { useFetching } from '../../hooks/useFetching';
import { fetcherList } from '../../shared/fetchers/fetcher-list';
import DashboardWrapper from '../../shared/components/dashboard/dashboard-wrapper';
import api from '../../api';
import { IError } from '../../shared/interfaces';
import apiResponseMock from '../../shared/mock/api-response.mock';
import { httpStatusCode } from '../../shared/constants/http-status-code';
import { fetcherKeys } from '../../shared/constants';
import {
  getSessionCookie,
  SessionContext,
  setSessionCookies,
} from '../../shared/context/session';
import { FirebaseApiInstance } from '../../api/firebase';
import { IJitSession } from '../api/auth/jitSession.interface';

const CalendarWrapperWithNoSSR = dynamic(
  () => import('../../shared/components/calendar-wrapper'),
  { ssr: false },
);

const Dashboard = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { setSession } = useContext(SessionContext);
  const {
    data: serviceToday = [],
    isLoading: isLoadingServiceForToday,
  } = useFetching<ServicesListProps[]>(
    fetcherList.serviceListForToday,
    fetcherKeys.serviceListToday,
  );

  const { data: tasksDataList = [] } = useFetching<ITaskProps[]>(
    fetcherList.tasksList,
    fetcherKeys.tasks,
  );
  const { data: userSession } = useFetching<IJitSession>(
    fetcherList.getUserSession,
    fetcherKeys.getSessionInformation,
  );
  const { data: taskToday = [], isLoading: isTaskForTodayLoading } = useFetching<
    ITaskProps[]
  >(fetcherList.taskListForToday, fetcherKeys.tasksListToday);

  const onDropTask = useCallback(async ({ event, revert }: EventDropArg) => {
    try {
      if (moment(event.start).isBefore(moment()) || !event?.start) {
        revert();
        addToast(t('dashboard.cannotChangeDateAndTimeToPast'), {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        const newTaskData = {
          taskId: event._def.extendedProps.taskId,
          startDate: moment(event.start).format('YYYY-MM-DD'),
          startTime: moment(event.start).format('HH:mm'),
        };
        await api.customer.updateTaskStartTime(newTaskData);
      }
    } catch (error) {
      const { statusCode } = (error as IError).response.data || apiResponseMock;
      if (statusCode !== httpStatusCode.requestTimeOut) {
        revert();
      }
      console.log(error);
    }
  }, []);

  const signInIntoFirebase = async (session) => {
    try {
      await FirebaseApiInstance.signIn(session?.token);
      const newSession = { ...session };
      delete newSession?.token;
      setSessionCookies(newSession);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const session = getSessionCookie();
    if (session?.token) {
      signInIntoFirebase(session);
    }
  }, []);
  useEffect(() => {
    if (userSession) {
      setSession(userSession);
    }
  }, [userSession]);

  return (
    <Container title={t('dashboard.title')}>
      <DashboardWrapper
        taskData={taskToday}
        isTaskLoading={isTaskForTodayLoading}
        isServiceLoading={isLoadingServiceForToday}
        serviceData={serviceToday}
      />
      <CalendarWrapperWithNoSSR
        tasksDataList={tasksDataList}
        onDrop={onDropTask}
      />
    </Container>
  );
};

export default Dashboard;
