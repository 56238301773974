import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ITaskProps } from '@digital.jit/services/customer/ui-models';
import { MDBDataTable } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../constants/routes';
import listSorting from '../../../helpers/list-sort';
import { IDashboardTaskListProps } from '../../../interfaces/Dashboard';
import styles from './dashboard-task-list.module.scss';
import shouldShowPagination from '../../../helpers/should-show-pagination';

const DashboardTaskList = ({
  tasksData,
  recordsFoundLabel,
}: IDashboardTaskListProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [taskListData, setTaskListData] = useState<ITaskProps[]>(tasksData);

  const data = {
    columns: [
      {
        label: t('dashboard.tasks.time'),
        field: 'startTime',
        width: 30,
        sort: 'asc',
      },
      {
        label: t('dashboard.tasks.task'),
        field: 'type',
        sort: 'asc',
        width: 260,
      },
      {
        label: t('dashboard.tasks.service'),
        field: 'serviceType',
        width: 80,
        sort: 'asc',
      },
      {
        label: t('dashboard.tasks.address'),
        field: 'address',
        width: 110,
        sort: 'asc',
      },
      {
        label: t('dashboard.tasks.customer'),
        field: 'customer',
        sort: 'asc',
        width: 290,
      },
    ],
    rows: taskListData.map((item) => ({
      ...item,
      startTime: (
        <div>
          <time className={styles['tasks-time']}>{item.startTime}</time>
        </div>
      ),
      type: (
        <div className={styles['tasks-type']}>
          <p>{item.type}</p>
        </div>
      ),
      serviceType: <p className={styles['tasks-item']}>{item.serviceType}</p>,
      address: (
        <div className={styles['tasks-item']}>
          <p>{item.address}</p>
        </div>
      ),
      customer: (
        <div className={`d-flex ${styles['tasks-customer-wrap']}`}>
          <div className={styles['tasks-customer-name-wrap']}>
            {item.customer?.companyName && (
              <p className={styles['tasks-customer']}>
                {item.customer.companyName}
              </p>
            )}
            <p className={styles['tasks-customer']}>
              {`${item.customer?.firstName || ''} ${
                item.customer?.lastName || ''
              }`}
            </p>
          </div>
          <p className={styles['tasks-customer-phone']}>
            {item.customer?.phoneNumber || ''}
          </p>
        </div>
      ),

      clickEvent: () => {
        router.push(routes.tasks.path(item.id));
      },
    })),
  };
  const sorting = (obj: { column: string; direction: string }) => {
    setTaskListData([
      ...listSorting<ITaskProps>(obj.column, obj.direction, taskListData),
    ]);
  };

  useEffect(() => {
    if (tasksData) {
      setTaskListData(tasksData);
    }
  }, [tasksData]);

  return (
    <MDBDataTable
      key={taskListData.length}
      responsive
      searching={false}
      data={data}
      theadColor="#333"
      hover
      className={styles['tasks-list']}
      info={false}
      displayEntries={false}
      onSort={sorting}
      paging={shouldShowPagination(tasksData)}
      noRecordsFoundLabel={recordsFoundLabel}
    />
  );
};

export default DashboardTaskList;
DashboardTaskList.defaultProps = {
  recordsFoundLabel: '',
};
