import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styles from './add-link.module.scss';

interface IInternalLink {
  pathname: string;
  query: Record<string, any>;
}

interface IAddLink {
  className?: string;
  link: string | IInternalLink;
  title: string;
}

const AddLink = ({ className, link, title }: IAddLink) => {
  const { t } = useTranslation();
  return (
    <Link href={link} as={link}>
      <a className={`${styles['add-link']} btn btn-success ${className}`}>
        <i className="bx bx-plus" />
        {t(title)}
      </a>
    </Link>
  );
};
export default AddLink;

AddLink.defaultProps = {
  className: '',
};
